const state = {
  toasts: []
};

const mutations = {
  pushToast(state, { message, handler }) {
    state.toasts = [...state.toasts, { message, handler }];
  },
  popToast(state) {
    const newState = [...state.toasts];
    newState.shift();
    state.toasts = newState;
  },
  removeToast(state, index) {
    const newState = [...state.toasts];
    newState.splice(index, 1);
    state.toasts = newState;
  }
};

const actions = {
  openToast({ commit }, { message, handler, duration }) {
    commit('pushToast', { message, handler });
    setTimeout(() => {
      commit('popToast');
    }, duration || 3000);
  },
  closeToast({ commit }, index) {
    commit('removeToast', index);
  }
};

const getters = {
  toasts(state) {
    return state.toasts;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
