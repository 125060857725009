import { shellConfig } from '@/core/config';

export default [
  {
    path: '',
    name: 'home-page',
    meta: { layout: shellConfig.layout.landing },
    component: () => import(/* webpackChunkName: 'home-page' */ '@/modules/landing/pages/HomePage.vue')
  }
];
