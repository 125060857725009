import Vuetify, {
  VLayout,
  VFlex,
  VRow,
  VCol,
  VNavigationDrawer,
  VListItem,
  VListItemTitle,
  VListItemSubtitle,
  VListItemIcon,
  VListItemContent,
  VListItemAction,
  VDivider,
  VCarousel,
  VCarouselItem,
  VWindow,
  VWindowItem,
  VIcon,
  VBtn,
  VInput,
  VTextField,
  VTab,
  VTabsItems,
  VTabItem,
  VSlider,
  VRangeSlider,
  VSnackbar,
  VSubheader,
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VSpacer
} from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';

const Components = {
  VLayout,
  VFlex,
  VRow,
  VCol,
  VNavigationDrawer,
  VListItem,
  VListItemTitle,
  VListItemSubtitle,
  VListItemIcon,
  VListItemContent,
  VListItemAction,
  VDivider,
  VCarousel,
  VCarouselItem,
  VWindow,
  VWindowItem,
  VIcon,
  VBtn,
  VInput,
  VTextField,
  VTab,
  VTabsItems,
  VTabItem,
  VSlider,
  VRangeSlider,
  VSnackbar,
  VSubheader,
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VSpacer
};

const opts = {
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: {
        primary: '#3f51b5',
        secondary: '#ffc300',
        accent: '#ff956f'
      }
    }
  }
};
export const VuetifyLib = Vuetify;
export const VuetifyOptions = {
  components: {
    ...Components
  },
  directives: {
    Ripple
  }
};
export default new Vuetify(opts);
