export default {
  moperty: {
    whatsappNumber: process.env.VUE_APP_MOPERTY_WHATSAPP_NUMBER,
    baseURL: process.env.VUE_APP_BASE_URL
  },
  pages: {
    home: '/',
    browse: '/browse',
    listingDetail: (title, id) => `/listing/${title}/${id}`,
    favoriteListing: '/favorite'
  },
  links: {
    whatsappMe: (phone, text) => `https://wa.me/${phone}?text=${text}`
  },
  categoryId: {
    house: 1,
    lands: 2,
    apartment: 3,
    warehouse: 4,
    shophouse: 5,
    building: 6
  },
  listingTypeId: {
    sale: 1,
    rent: 2
  },
  detail: {
    name: {
      BUILDING_SIZE: 'Luas Bangunan',
      LAND_SIZE: 'Luas Tanah'
    },
    type: {
      CATEGORY: 'Category',
      LISTING: 'Listing'
    }
  },
  localStorage: {
    mopertyGuestKey: 'moperty:guestId',
    lang: 'moperty:lang'
  }
};
