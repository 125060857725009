import { SidebarMixin } from '@/core/mixins';

const LandingHeader = () => import(/* webpackChunkName: 'landing-header' */ '@/shell/headers/LandingHeader.vue');
const InfoFooter = () => import(/* webpackChunkName: 'info-footer' */ '@/shell/footers/InfoFooter.vue');
const NavSidebar = () => import(/* webpackChunkName: 'nav-sidebar' */ '@/components/nav/NavSidebar.vue');
const NavFooter = () => import(/* webpackChunkName: 'nav-footer' */ '@/components/nav/NavFooter.vue');

export default {
  name: 'LandingLayout',
  components: {
    LandingHeader,
    InfoFooter,
    NavSidebar,
    NavFooter
  },
  mixins: [SidebarMixin],
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      scrolledHeader: false,
      sidebarOpen: false
    };
  },
  methods: {
    handleScroll() {
      this.scrolledHeader = (window.scrollY > 0);
    }
  }
};
