export const saveToLocalStorage = (key, value) => {
  if (typeof value === 'object') {
    const jsonString = JSON.stringify(value);
    window.localStorage.setItem(key, jsonString);
  } else {
    window.localStorage.setItem(key, value);
  }
};

export const getFromLocalStorage = (key) => {
  const value = window.localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const removeFromLocalStorage = (key) => {
  window.localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
};
