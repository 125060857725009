import { appConfig } from '@/core/config';

const state = {
  listingIds: [],
  activeFilters: {},
  pagination: {}
};

const mutations = {
  setListingIds(state, ids) {
    state.listingIds = [...ids];
  },
  addListingIds(state, ids) {
    state.listingIds = [...state.listingIds, ...ids];
  },
  setPagination(state, pagination) {
    state.pagination = { ...pagination };
  },
  setActiveFilters(state, filters) {
    state.activeFilters = { ...filters };
  }
};

const actions = {
  browseListingsSuccess({ commit }, {
    listingIds,
    pagination,
    filters,
    isLoadMore
  }) {
    if (isLoadMore) {
      commit('addListingIds', listingIds);
    } else {
      commit('setListingIds', listingIds);
    }
    commit('setPagination', pagination);
    commit('setActiveFilters', filters);
  }
};

const getters = {
  browseResults(state, getters, rootState, rootGetters) {
    const { listingIds } = state;
    const { publishedListingMap } = rootState.publishedListing;
    const { propertyMap } = rootState.property;
    const getMainImage = rootGetters['property/getMainImage'];
    const getSpecificPropertyDetails = rootGetters['property/getSpecificPropertyDetails'];

    const listingData = listingIds.map(id => publishedListingMap[id]);
    return listingData.map(i => ({
      ...i,
      ...propertyMap[i.property_id],
      details: getSpecificPropertyDetails(i.property_id, [
        appConfig.detail.name.LAND_SIZE,
        appConfig.detail.name.BUILDING_SIZE
      ]),
      image: getMainImage(i.property_id)
    }));
  },
  pagination(state) {
    return state.pagination || {};
  },
  browseFilters(state) {
    return state.activeFilters || {};
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
