import Toast from '@/components/Toast.vue';
import LandingLayout from '@/shell/layouts/LandingLayout.vue';
import AppLayout from '@/shell/layouts/AppLayout.vue';
import StackedLayout from '@/shell/layouts/StackedLayout.vue';
import ListingDetailLayout from '@/shell/layouts/ListingDetailLayout.vue';

const GlobalComponentsPlugin = {
  install(Vue) {
    Vue.component('Toast', Toast);
    Vue.component('LandingLayout', LandingLayout);
    Vue.component('AppLayout', AppLayout);
    Vue.component('StackedLayout', StackedLayout);
    Vue.component('ListingDetailLayout', ListingDetailLayout);
  }
};

export default GlobalComponentsPlugin;
