import { mapGetters, mapActions } from 'vuex';

const DEFAULT_BOTTOM_POSITION = 60;

export default {
  name: 'Toast',
  props: {
    bottom: {
      type: Number,
      default: DEFAULT_BOTTOM_POSITION
    }
  },
  computed: {
    ...mapGetters('ui/toast', [
      'toasts'
    ]),
    toastStyles() {
      return {
        bottom: `${this.bottom}px`
      };
    }
  },
  methods: {
    ...mapActions('ui/toast', [
      'closeToast'
    ])
  }
};
