import { shellConfig } from '@/core/config';

export default [
  {
    path: '',
    component: () => import(/* webpackChunkName: 'browse-page' */ '@/modules/browse/pages/BrowsePage.vue'),
    name: 'browse-page',
    meta: {
      layout: shellConfig.layout.app,
      navFooter: true
    }
  }
];
