import Vue from 'vue';
import PortalVue from 'portal-vue';
import VueClipboard from 'vue-clipboard2';
import VueMeta from 'vue-meta';

import router from '@/core/router';
import store from '@/core/store';
import { i18n } from '@/core/i18n';
import vuetify, { VuetifyLib, VuetifyOptions } from '@/core/plugins/vuetify';
import GlobalComponents from '@/core/plugins/global-components';
import '@/assets/scss/styles.scss';

import App from './App.vue';
import './registerServiceWorker';

Vue.use(VuetifyLib, VuetifyOptions);
Vue.use(PortalVue);
Vue.use(GlobalComponents);
Vue.use(VueClipboard);
Vue.use(VueMeta);

Vue.config.productionTip = false;
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
}

new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app');
