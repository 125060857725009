import mopertyApi from '@/core/api/moperty-api';

const state = {
  guestId: '',
  favoriteListingMap: {},
  favoriteListingIds: []
};

const mutations = {
  setGuestId(state, id) {
    state.guestId = id;
  },
  setFavoriteListingMap(state, favoriteListings) {
    state.favoriteListingMap = favoriteListings.reduce((favListingMap, favListing) => {
      const {
        published_listing_id: id,
        image_url: imageUrl,
        price_per_meter: pricePerMeter,
        title,
        location,
        price,
      } = favListing;
      return {
        ...favListingMap,
        [id]: {
          id,
          title,
          location,
          price,
          imageUrl,
          pricePerMeter
        }
      };
    }, {});
  },
  setFavoriteListingIds(state, favoriteListings) {
    state.favoriteListingIds = favoriteListings.map(i => i.published_listing_id);
  },
  addFavoriteListing(state, {
    id, title, location, price
  }) {
    state.favoriteListingMap = {
      ...state.favoriteListingMap,
      [id]: {
        id,
        title,
        location,
        price
      }
    };
  },
  addFavoriteListingId(state, listingId) {
    state.favoriteListingIds = [...state.favoriteListingIds, listingId];
  },
  removeFavoriteListing(state, listingId) {
    state.favoriteListingIds = state.favoriteListingIds.filter(id => id !== listingId);
  }
};

const actions = {
  initializeGuestId({ commit }, guestId) {
    commit('setGuestId', guestId);
  },
  createGuestId({ commit }, { successCb, errCb }) {
    mopertyApi.createGuestId((res) => {
      const { id } = res.data;
      commit('setGuestId', id);
      successCb(id);
    }, errCb);
  },
  getFavoriteListings({ getters, commit }, { successCb }) {
    const { guestId } = getters;
    mopertyApi.getFavoriteListings(guestId, ({ data }) => {
      commit('setFavoriteListingMap', data);
      commit('setFavoriteListingIds', data);
      if (successCb) {
        successCb();
      }
    });
  },
  saveFavoriteListing({ getters, commit }, {
    listing, successCb, errCb
  }) {
    const { guestId } = getters;
    const data = {
      id: guestId,
      listingId: listing.id
    };
    mopertyApi.saveFavoriteListing(data, () => {
      commit('addFavoriteListing', listing);
      commit('addFavoriteListingId', listing.id);
      successCb();
    }, errCb);
  },
  removeFavoriteListing({ getters, commit }, {
    listingId, successCb, errCb
  }) {
    const { guestId } = getters;
    const data = { id: guestId, listingId };
    mopertyApi.deleteFavoriteListing(data, () => {
      commit('removeFavoriteListing', listingId);
      if (successCb) {
        successCb();
      }
    }, errCb);
  },
  verifyFavoriteListing({ getters }, { listingId, successCb }) {
    const { guestId } = getters;
    const data = {
      id: guestId,
      listingId
    };
    mopertyApi.getSingleFavoriteListing(data, (res) => {
      const { published_listing_id: id } = res.data;
      successCb(id);
    });
  }
};

const getters = {
  guestId(state) {
    return state.guestId;
  },
  favoriteListings({ favoriteListingIds, favoriteListingMap }) {
    return favoriteListingIds.map(id => favoriteListingMap[id]);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
