export default {
  data() {
    return {
      sidebarOpen: false
    };
  },
  computed: {
    sidebarOverlayActive() {
      return this.sidebarOpen;
    }
  },
  watch: {
    sidebarOverlayActive(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
        return;
      }
      document.body.style.overflow = null;
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    }
  }
};
