import Vue from 'vue';
import Router from 'vue-router';
import LandingRoutes from '@/modules/landing/routes';
import BrowseRoutes from '@/modules/browse/routes';
import ListingRoutes from '@/modules/listing/routes';
import FavoriteModuleRoutes from '@/modules/favorite/routes';
import { loadLanguageAsync } from '@/core/i18n';
import scrollBehavior from './scroll-behavior';
import store from '@/core/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: 'landing' */ '@/modules/landing/Landing.vue'),
      children: LandingRoutes
    },
    {
      path: '/browse',
      component: () => import(/* webpackChunkName: 'browse' */ '@/modules/browse/Browse.vue'),
      children: BrowseRoutes
    },
    {
      path: '/favorite',
      component: () => import (/* webpackChunkName: 'favorite-module' */'@/modules/favorite/FavoriteModule.vue'),
      children: FavoriteModuleRoutes
    },
    {
      path: '/listing/:title/:id',
      component: () => import (/* webpackChunkName: 'listing' */'@/modules/listing/Listing.vue'),
      children: ListingRoutes
    }
  ],
  scrollBehavior
});

router.beforeEach((to, from, next) => {
  const lang = store.getters['ui/language/activeLang'];
  loadLanguageAsync(lang).then(() => next());
});

export default router;
