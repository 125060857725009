import { loadLanguageAsync } from '@/core/i18n';
import { getFromLocalStorage, saveToLocalStorage } from '@/core/utils/local-storage';
import { appConfig } from '@/core/config';

const state = {
  activeLang: 'id',
  isInitialized: false
};

const mutations = {
  setActiveLang(state, lang) {
    state.activeLang = lang;
  },
  setIsInitialized(state, value) {
    state.isInitialized = value;
  }
};

const actions = {
  initializeLanguage({ commit }) {
    const savedLang = getFromLocalStorage(appConfig.localStorage.lang);
    if (savedLang) {
      loadLanguageAsync(savedLang);
      commit('setActiveLang', savedLang);
    }
    commit('setIsInitialized', true);
  },
  changeLanguage({ commit }, lang) {
    loadLanguageAsync(lang);
    saveToLocalStorage(appConfig.localStorage.lang, lang);
    commit('setActiveLang', lang);
  }
};

const getters = {
  activeLang(state) {
    if (!state.isInitialized) {
      return getFromLocalStorage(appConfig.localStorage.lang) || state.activeLang;
    }
    return state.activeLang;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
