import axios from 'axios';
import { apiConfig } from '@/core/config';

require('mock-routes');

const mopertyApiClient = axios.create({
  baseURL: apiConfig.moperty.base
});

const createRequest = (options, onSuccess, onError) => mopertyApiClient
  .request({
    ...options
  })
  .then(res => onSuccess && onSuccess(res.data))
  .catch(err => onError && onError(err));

export const httpGet = (url, onSuccess, onError, params = {}) => createRequest({
  method: 'get',
  url,
  params
}, onSuccess, onError);

export const httpPost = (url, onSuccess, onError, data = {}) => createRequest({
  method: 'post',
  url,
  data
}, onSuccess, onError);

export const httpDelete = (url, onSuccess, onError, params = {}) => createRequest({
  method: 'delete',
  url,
  params
}, onSuccess, onError);
