import { shellConfig } from '@/core/config';

export default [
  {
    path: 'image-preview',
    component: () => import(
      /* webpackChunkName: 'listing-image-preview' */ '@/modules/listing/pages/ListingImagePreview.vue'),
    name: 'listing-image-preview',
    meta: {
      transitionName: 'slide-fade',
      layout: shellConfig.layout.stacked
    }
  },
  {
    path: '',
    component: () => import(
      /* webpackChunkName: 'listing-detail-page' */ '@/modules/listing/pages/ListingDetailPage.vue'),
    name: 'listing-detail',
    meta: {
      layout: shellConfig.layout.listingDetail,
      navFooter: false
    }
  }
];
