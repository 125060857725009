import { shellConfig } from '@/core/config';

export default [
  {
    path: '',
    component: () => import(
      /* webpackChunkName: 'favorite-listing-page' */ '@/modules/favorite/pages/FavoriteListingPage.vue'),
    name: 'favorite-listing-page',
    meta: {
      layout: shellConfig.layout.app,
      navFooter: true
    }
  }
];
