import mopertyApi from '@/core/api/moperty-api';

const state = {
  propertyMap: {},
  propertyDetailMap: {},
  propertyImageMap: {}
};

const mutations = {
  setPropertyMap(state, payload) {
    const prevState = { ...state.propertyMap };
    state.propertyMap = payload.reduce((propertyMap, property) => {
      const { details, images, ...propertyData } = property;
      return {
        ...propertyMap,
        [property.property_id]: propertyData
      };
    }, prevState);
  },
  setPropertyDetailMap(state, properties) {
    const prevState = { ...state.propertyDetailMap };
    state.propertyDetailMap = properties.reduce((propertyDetailMap, property) => {
      const { details } = property;
      return {
        ...propertyDetailMap,
        [property.property_id]: details
      };
    }, prevState);
  },
  setPropertyImageMap(state, properties) {
    const prevState = { ...state.propertyImageMap };
    state.propertyImageMap = properties.reduce((propertyImageMap, property) => {
      const { image } = property;
      return {
        ...propertyImageMap,
        [property.property_id]: image
      };
    }, prevState);
  },
  setProperty(state, payload) {
    const id = payload.property_id;
    state.propertyMap = {
      ...state.propertyMap,
      [id]: {
        ...state.propertyMap[id],
        ...payload
      }
    };
  },
  setPropertyDetails(state, { id, details }) {
    state.propertyDetailMap = {
      ...state.propertyDetailMap,
      [id]: details
    };
  },
  setPropertyImages(state, { id, images }) {
    state.propertyImageMap = {
      ...state.propertyImageMap,
      [id]: images
    };
  }
};

const actions = {
  getPublishedListingsSuccess({ commit }, properties) {
    commit('setPropertyMap', properties);
    commit('setPropertyDetailMap', properties);
    commit('setPropertyImageMap', properties);
  },
  getProperty({ commit }, { id, successCb }) {
    mopertyApi.getPropertyInfo((res) => {
      const { data } = res;
      commit('setProperty', data);
      if (successCb) successCb();
    }, id);
  },
  getPropertyDetails({ commit }, { id, successCb }) {
    mopertyApi.getPropertyDetails((res) => {
      const { data } = res;
      commit('setPropertyDetails', { id, details: data });
      if (successCb) successCb();
    }, id);
  },
  getPropertyImages({ commit }, { id, successCb }) {
    mopertyApi.getPropertyImages((res) => {
      const { data } = res;
      commit('setPropertyImages', { id, images: data });
      if (successCb) successCb();
    }, id);
  }
};

const getters = {
  propertyMap(state) {
    return state.propertyMap || {};
  },
  propertyDetailMap(state) {
    return state.propertyDetailMap || {};
  },
  propertyImageMap(state) {
    return state.propertyImageMap || {};
  },
  propertyById(state, getters) {
    const { propertyMap } = getters;
    return id => propertyMap[id] || {};
  },
  propertyDetailById(state, getters) {
    const { propertyDetailMap } = getters;
    return id => propertyDetailMap[id] || [];
  },
  propertyImageById(state, getters) {
    const { propertyImageMap } = getters;
    return (id, sortByMainImg = false) => {
      const images = propertyImageMap[id];
      if (!images || images.length === 0) return [];
      if (!sortByMainImg) return images;

      return [...images].sort((a, b) => {
        if (a.is_main) return -1;
        if (b.is_main) return 1;
        return -1;
      });
    };
  },
  getSpecificPropertyDetails(state, getters) {
    const { propertyDetailById } = getters;
    return (propertyId, detailNames) => propertyDetailById(propertyId)
      .filter(x => detailNames.includes(x.detail_name));
  },
  getMainImage(state, getters) {
    const { propertyImageById } = getters;
    return (propertyId) => {
      const images = propertyImageById(propertyId);
      return images.length > 0 ? images.find(x => x.is_main) : {};
    };
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
