import { mapActions } from 'vuex';
import { getFromLocalStorage, saveToLocalStorage } from '@/core/utils/local-storage';
import { appConfig } from '@/core/config';
import ServiceWorkerUpdateMixin from '@/core/mixins/service-worker-update-mixin';

const AppUpdateCard = () => import(
  /* webpackChunkName: 'app-update-card' */ '@/components/AppUpdateCard.vue');

const { mopertyGuestKey } = appConfig.localStorage;

export default {
  components: {
    AppUpdateCard
  },
  mixins: [ServiceWorkerUpdateMixin],
  created() {
    this.getCategories();
    this.getGuestId();
    this.initializeLanguage();
  },
  methods: {
    ...mapActions('category', [
      'getCategories'
    ]),
    ...mapActions('guest', [
      'initializeGuestId',
      'createGuestId'
    ]),
    ...mapActions('ui/language', ['initializeLanguage']),
    getGuestId() {
      const guestId = getFromLocalStorage(mopertyGuestKey);
      if (!guestId) {
        this.createGuestId({
          successCb: this.createGuestIdSuccess
        });
      } else {
        this.initializeGuestId(guestId);
      }
    },
    createGuestIdSuccess(id) {
      saveToLocalStorage(mopertyGuestKey, id);
    }
  }
};
