export default {
  data() {
    return {
      isRefreshing: false,
      visibleAppUpdatePrompt: false,
      registration: null
    };
  },
  created() {
    document.addEventListener('swUpdated', this.showAppUpdatePrompt, { once: true });

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.isRefreshing) return;
      this.isRefreshing = true;
      window.location.reload();
    });
  },
  methods: {
    showAppUpdatePrompt(event) {
      this.registration = event.detail;
      this.visibleAppUpdatePrompt = true;
    },
    refreshApp() {
      this.visibleAppUpdatePrompt = false;
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  }
};
