import { SidebarMixin } from '@/core/mixins';

const NavSidebar = () => import(/* webpackChunkName: 'nav-sidebar' */ '@/components/nav/NavSidebar.vue');
const NavFooter = () => import(/* webpackChunkName: 'nav-footer' */ '@/components/nav/NavFooter.vue');
const ListingDetailHeader = () => import(/* webpackChunkName: 'listing-detail-header' */ '@/shell/headers/ListingDetailHeader.vue');

export default {
  name: 'ListingDetailLayout',
  components: {
    ListingDetailHeader,
    NavSidebar,
    NavFooter
  },
  mixins: [SidebarMixin],
  data() {
    return {
      previousUrl: '',
      searchbarActive: false
    };
  },
  computed: {
    withNavFooter() {
      return this.$route.meta && this.$route.meta.navFooter;
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    toggleSearchbar() {
      this.searchbarActive = !this.searchbarActive;
    }
  },
  watch: {
    searchbarActive(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
        return;
      }
      document.body.style.overflow = null;
    }
  }
};
