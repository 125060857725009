import mopertyApi from '@/core/api/moperty-api';

const state = {
  publishedListingMap: {},
  publishedListingIds: [],
  pagination: {}
};

const mutations = {
  setPublishedListingMap(state, payload) {
    const prevState = { ...state.publishedListingMap };
    state.publishedListingMap = payload.reduce((publishedListingMap, listing) => ({
      ...publishedListingMap,
      [listing.id]: listing
    }), prevState);
  },
  setPublishedListingIds(state, publishedListingIds) {
    const newIds = publishedListingIds
      .filter(listingId => !state.publishedListingIds.some(id => id === listingId));
    state.publishedListingIds = [...state.publishedListingIds, ...newIds];
  },
  setPagination(state, payload) {
    state.pagination = { ...payload };
  },
  addPublishedListing(state, payload) {
    const { id } = payload;
    state.publishedListingMap = {
      ...state.publishedListingMap,
      [id]: {
        ...state.publishedListingMap[id],
        ...payload
      }
    };
  }
};

const actions = {
  getPublishedListings({ commit, dispatch }, { params, success }) {
    mopertyApi.getPublishedListings((response) => {
      const { items, ...pagination } = response.data;
      const publishedListings = [];
      const properties = [];
      const ids = items.map(i => i.id);
      if (items.length > 0) {
        items.forEach((i) => {
          const { property, ...publishedListing } = i;
          publishedListings.push(publishedListing);
          properties.push(property);
        });
        commit('setPagination', pagination);
        commit('setPublishedListingMap', publishedListings);
        commit('setPublishedListingIds', ids);
        dispatch('property/getPublishedListingsSuccess', properties, { root: true });
      }
      success({
        listingIds: ids,
        pagination
      });
    }, params);
  },
  getListingInfo({ commit }, { id, successCb }) {
    mopertyApi.getListingInfo((response) => {
      const { data } = response;
      commit('addPublishedListing', data);
      if (successCb) successCb(data);
    }, id);
  }
};

const getters = {
  publishedListingMap(state) {
    return state.publishedListingMap || {};
  },
  publishedListingIds(state) {
    return state.publishedListingIds || [];
  },
  getPublishedListingById(state, getters) {
    const { publishedListingMap } = getters;
    return id => publishedListingMap[id] || {};
  },
  pagination(state) {
    return state.pagination;
  },
  allPublishedListings(state, getters) {
    const { publishedListingMap, publishedListingIds } = getters;
    return publishedListingIds.map(id => publishedListingMap[id]);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
