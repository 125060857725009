import mopertyApi from '@/core/api/moperty-api';

const state = {
  categoriesMap: {},
  categoryIds: []
};

const mutations = {
  setCategoriesMap(state, categories) {
    state.categoriesMap = categories.reduce((categoriesMap, category) => ({
      ...categoriesMap,
      [category.category_id]: category
    }), {});
  },
  setCategoryIds(state, categories) {
    state.categoryIds = categories.map(category => category.category_id);
  }
};

const actions = {
  getCategories({ commit }) {
    mopertyApi.getCategories((response) => {
      commit('setCategoriesMap', response.data);
      commit('setCategoryIds', response.data);
    });
  }
};

const getters = {
  categoriesMap(state) {
    return state.categoriesMap;
  },
  allCategories(state, getters) {
    const { categoriesMap } = getters;
    return state.categoryIds.map(id => categoriesMap[id]);
  },
  getCategoryName(state, getters) {
    const { categoriesMap } = getters;
    return (id) => {
      const category = categoriesMap[id] || {};
      return category.category_name || '';
    };
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
