import toastModule from './toast';
import languageModule from './language';

export default {
  namespaced: true,
  modules: {
    toast: toastModule,
    language: languageModule
  }
};
