export default {
  moperty: {
    base: process.env.VUE_APP_MOPERTY_API_BASE,
    guests: {
      createGuestId: '/usr/guests',
      getFavoriteListings: id => `/usr/guests/${id}/favorite_listing`,
      getSingleFavoriteListing: (id, listingId) => `/usr/guests/${id}/favorite_listing/${listingId}`,
      saveFavoriteListing: id => `/usr/guests/${id}/favorite_listing`,
      deleteFavoriteListing: (id, listingId) => `/usr/guests/${id}/favorite_listing/${listingId}`
    },
    categories: {
      getCategories: '/lst/categories'
    },
    publishedListing: {
      getPublishedListings: '/lst/published_listings',
      getListingInfo: id => `/lst/published_listings/${id}`
    },
    properties: {
      getPropertyInfo: id => `/lst/properties/${id}`,
      getPropertyDetails: id => `/lst/properties/${id}/details`,
      getImages: id => `/lst/properties/${id}/images`
    }
  }
};
