import Vue from 'vue';
import Vuex from 'vuex';
import categoryModule from './modules/category';
import publishedListingModule from './modules/published-listing';
import propertyModule from './modules/property';
import browseModule from './modules/browse';
import guestModule from './modules/guest';
import uiModule from './modules/ui';

Vue.use(Vuex);
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
}

export default new Vuex.Store({
  modules: {
    category: categoryModule,
    publishedListing: publishedListingModule,
    property: propertyModule,
    browse: browseModule,
    guest: guestModule,
    ui: uiModule
  }
});
