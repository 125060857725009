import { apiConfig } from '@/core/config';
import { httpGet, httpPost, httpDelete } from '@/core/http';

export default {
  getCategories(successCb, errCb) {
    return httpGet(apiConfig.moperty.categories.getCategories, successCb, errCb);
  },
  getPublishedListings(successCb, params = {}, errCb) {
    const { getPublishedListings } = apiConfig.moperty.publishedListing;
    return httpGet(getPublishedListings, successCb, errCb, params);
  },
  getListingInfo(successCb, id, errCb) {
    return httpGet(apiConfig.moperty.publishedListing.getListingInfo(id), successCb, errCb);
  },
  getPropertyInfo(successCb, id, errCb) {
    return httpGet(apiConfig.moperty.properties.getPropertyInfo(id), successCb, errCb);
  },
  getPropertyDetails(successCb, id, errCb) {
    return httpGet(apiConfig.moperty.properties.getPropertyDetails(id), successCb, errCb);
  },
  getPropertyImages(successCb, id, errCb) {
    return httpGet(apiConfig.moperty.properties.getImages(id), successCb, errCb);
  },
  createGuestId(successCb, errCb) {
    return httpPost(apiConfig.moperty.guests.createGuestId, successCb, errCb);
  },
  getFavoriteListings(id, successCb, errCb) {
    return httpGet(apiConfig.moperty.guests.getFavoriteListings(id), successCb, errCb);
  },
  getSingleFavoriteListing({ id, listingId }, successCb, errCb) {
    return httpGet(apiConfig.moperty.guests.getSingleFavoriteListing(id, listingId), successCb, errCb);
  },
  saveFavoriteListing({ id, listingId }, successCb, errCb) {
    const data = {
      published_listing_id: listingId
    };
    return httpPost(apiConfig.moperty.guests.saveFavoriteListing(id), successCb, errCb, data);
  },
  deleteFavoriteListing({ id, listingId }, successCb, errCb) {
    return httpDelete(apiConfig.moperty.guests.deleteFavoriteListing(id, listingId), successCb, errCb);
  }
};
